.slider {
    display: inline-block;
    vertical-align: middle;
    position: relative;
  }
  .slider.slider-horizontal {
    width: 210px;
    height: 20px;
  }
  .slider.slider-horizontal .slider-track {
    height: 10px;
    width: 100%;
    margin-top: -5px;
    top: 50%;
    left: 0;
  }
  .slider.slider-horizontal .slider-selection, .slider.slider-horizontal .slider-track-low, .slider.slider-horizontal .slider-track-high {
    height: 100%;
    top: 0;
    bottom: 0;
  }
  .slider.slider-horizontal .slider-tick, .slider.slider-horizontal .slider-handle {
    margin-left: -10px;
  }
  .slider.slider-horizontal .slider-tick.triangle, .slider.slider-horizontal .slider-handle.triangle {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    border-width: 0 10px 10px 10px;
    width: 0;
    height: 0;
    border-bottom-color: #2e6da4;
    margin-top: 0;
  }
  .slider.slider-horizontal .slider-tick-container {
    white-space: nowrap;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .slider.slider-horizontal .slider-tick-label-container {
    white-space: nowrap;
    margin-top: 20px;
  }
  .slider.slider-horizontal .slider-tick-label-container .slider-tick-label {
    padding-top: 4px;
    display: inline-block;
    text-align: center;
  }
  .slider.slider-horizontal .tooltip {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .slider.slider-horizontal.slider-rtl .slider-track {
    left: initial;
    right: 0;
  }
  .slider.slider-horizontal.slider-rtl .slider-tick, .slider.slider-horizontal.slider-rtl .slider-handle {
    margin-left: initial;
    margin-right: -10px;
  }
  .slider.slider-horizontal.slider-rtl .slider-tick-container {
    left: initial;
    right: 0;
  }
  .slider.slider-horizontal.slider-rtl .tooltip {
    -webkit-transform: translateX(50%);
            transform: translateX(50%);
  }
  .slider.slider-vertical {
    height: 210px;
    width: 20px;
  }
  .slider.slider-vertical .slider-track {
    width: 10px;
    height: 100%;
    left: 25%;
    top: 0;
  }
  .slider.slider-vertical .slider-selection {
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
  }
  .slider.slider-vertical .slider-track-low, .slider.slider-vertical .slider-track-high {
    width: 100%;
    left: 0;
    right: 0;
  }
  .slider.slider-vertical .slider-tick, .slider.slider-vertical .slider-handle {
    margin-top: -10px;
  }
  .slider.slider-vertical .slider-tick.triangle, .slider.slider-vertical .slider-handle.triangle {
    border-width: 10px 0 10px 10px;
    width: 1px;
    height: 1px;
    border-left-color: #2e6da4;
    border-right-color: #2e6da4;
    margin-left: 0;
    margin-right: 0;
  }
  .slider.slider-vertical .slider-tick-label-container {
    white-space: nowrap;
  }
  .slider.slider-vertical .slider-tick-label-container .slider-tick-label {
    padding-left: 4px;
  }
  .slider.slider-vertical .tooltip {
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
  .slider.slider-vertical.slider-rtl .slider-track {
    left: initial;
    right: 25%;
  }
  .slider.slider-vertical.slider-rtl .slider-selection {
    left: initial;
    right: 0;
  }
  .slider.slider-vertical.slider-rtl .slider-tick.triangle, .slider.slider-vertical.slider-rtl .slider-handle.triangle {
    border-width: 10px 10px 10px 0;
  }
  .slider.slider-vertical.slider-rtl .slider-tick-label-container .slider-tick-label {
    padding-left: initial;
    padding-right: 4px;
  }
  .slider.slider-disabled .slider-handle {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #dfdfdf), to(#bebebe));
    background-image: linear-gradient(to bottom, #dfdfdf 0, #bebebe 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdfdfdf',endColorstr='#ffbebebe',GradientType=0);
    background-repeat: repeat-x;
  }
  .slider.slider-disabled .slider-track {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #e5e5e5), to(#e9e9e9));
    background-image: linear-gradient(to bottom, #e5e5e5 0, #e9e9e9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe5e5e5',endColorstr='#ffe9e9e9',GradientType=0);
    background-repeat: repeat-x;
    cursor: not-allowed;
  }
  .slider input {
    display: none;
  }
  .slider .tooltip {
    pointer-events: none;
  }
  .slider .tooltip.top {
    margin-top: -36px;
  }
  .slider .tooltip-inner {
    white-space: nowrap;
    max-width: none;
  }
  .slider .hide {
    display: none;
  }
  .slider-track {
    position: absolute;
    cursor: pointer;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #f5f5f5), to(#f9f9f9));
    background-image: linear-gradient(to bottom, #f5f5f5 0, #f9f9f9 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5',endColorstr='#fff9f9f9',GradientType=0);
    background-repeat: repeat-x;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  .slider-selection {
    position: absolute;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #f9f9f9), to(#f5f5f5));
    background-image: linear-gradient(to bottom, #f9f9f9 0, #f5f5f5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9f9f9',endColorstr='#fff5f5f5',GradientType=0);
    background-repeat: repeat-x;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .slider-selection.tick-slider-selection {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #8ac1ef), to(#82b3de));
    background-image: linear-gradient(to bottom, #8ac1ef 0, #82b3de 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8ac1ef',endColorstr='#ff82b3de',GradientType=0);
    background-repeat: repeat-x;
  }
  .slider-track-low, .slider-track-high {
    position: absolute;
    background: transparent;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
  }
  .slider-handle {
    position: absolute;
    top: 0;
    width: 20px;
    height: 20px;
    background-color: #337ab7;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #337ab7), to(#2e6da4));
    background-image: linear-gradient(to bottom, #337ab7 0, #2e6da4 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7',endColorstr='#ff2e6da4',GradientType=0);
    background-repeat: repeat-x;
    -webkit-filter: none;
            filter: none;
    -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
    border: 0 solid transparent;
  }
  .slider-handle:hover {
    cursor: pointer;
  }
  .slider-handle.round {
    border-radius: 50%;
  }
  .slider-handle.triangle {
    background: transparent none;
  }
  .slider-handle.custom {
    background: transparent none;
  }
  .slider-handle.custom::before {
    line-height: 20px;
    font-size: 20px;
    content: '\2605';
    color: #726204;
  }
  .slider-tick {
    position: absolute;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #f9f9f9), to(#f5f5f5));
    background-image: linear-gradient(to bottom, #f9f9f9 0, #f5f5f5 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff9f9f9',endColorstr='#fff5f5f5',GradientType=0);
    background-repeat: repeat-x;
    -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-filter: none;
            filter: none;
    opacity: .8;
    border: 0 solid transparent;
  }
  .slider-tick.round {
    border-radius: 50%;
  }
  .slider-tick.triangle {
    background: transparent none;
  }
  .slider-tick.custom {
    background: transparent none;
  }
  .slider-tick.custom::before {
    line-height: 20px;
    font-size: 20px;
    content: '\2605';
    color: #726204;
  }
  .slider-tick.in-selection {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #8ac1ef), to(#82b3de));
    background-image: linear-gradient(to bottom, #8ac1ef 0, #82b3de 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8ac1ef',endColorstr='#ff82b3de',GradientType=0);
    background-repeat: repeat-x;
    opacity: 1;
  }