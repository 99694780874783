/* IRANYekan Font */
@font-face {
    font-family: 'IRANYekan';
    src: url('../../../public/Hostlar/assets/fonts/iranyekan/IRANYekanXVF.woff') format('woff-variations'),
    url('../../../public/Hostlar/assets/fonts/iranyekan/IRANYekanXVF.woff') format('woff');
    font-weight: 100 1000;
    font-display: fallback; 

}

/* IRANSans Font */
@font-face {
    font-family: 'iransans';
    src: url('../../../public/Hostlar/assets/fonts/iransans/IRANSansXV.woff') format('woff-variations'),
    url('../../../public/Hostlar/assets/fonts/iransans/IRANSansXV.woff') format('woff');
    font-weight: 100 900;
    font-display: fallback;

}

/* Dana Font */
@font-face { 
    font-family: 'dana';
    src: url('../../../public/Hostlar/assets/fonts/dana/DanaVF.woff') format('woff-variations'),
    url('../../../public/Hostlar/assets/fonts/dana/DanaVF.woff') format('woff');
    font-weight: 100 900;
    font-display: fallback;
}