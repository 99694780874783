/*================
Template Name: Hostlar قالب هاستینگ و دامنه
Description: All type of web hosting provider or company with WHMCS template.
Version: 10.0.1
Author: https://rtl-theme.com/author/chaveamin
=======================*/
:root {
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #007bff;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #f8f9fa;
    --dark: #343a40;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-primary: "IRANYekan", tahoma;
    --font-family-secondary: "iransans", tahoma;
  }
  html { scroll-behavior: smooth; }
  body {
    direction: rtl;
    background-color: #ffffff;
    font-family: var(--font-family-primary);
    color: #575757;
    font-weight: 400;
    line-height: 1.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
  }
  .h1,
  .display-1,
  .h2,
  .display-2,
  .h3,
  .display-3,
  .h4,
  .display-4,
  .h5,
  .display-5,
  .h6,
  .display-6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-family-primary);
    color: #0d1536;
  }
  
  @media (min-width: 1200px) {
    .h2,
    h2 {
      font-size: 2.1875rem;
    }
  }
  .h5,
  .h6,
  h5,
  h6 {
    font-weight: 600;
    margin-bottom: 0.375rem;
  }
  .h5,
  h5 {
    font-size: 1.125rem;
  }
  .h1,
  .h2,
  .h3,
  .h4,
  h1,
  h2,
  h3,
  h4 {
    font-weight: 800;
  }
  .lead {
    font-size: 1.125rem;
    font-weight: 400;
    font-family: var(--font-family-secondary);
    font-variation-settings: "dots" 0
  }
  p:not(:last-child) {
    margin-bottom: 1.25em;
  }
  ul,
  ol {
    margin-bottom: 0;
    padding: 0;
  }
  li {
    list-style: none;
    font-family: var(--font-family-secondary);
  }
  strong,
  b {
    font-weight: 600 !important;
    font-family: var(--font-family-primary);
  }
  .badge {
    padding: 0.25rem 0.45rem;
    font-family: var(--font-family-primary);
    font-weight: 500;
    font-size: 10px;
    line-height: 11px;
  }
  .badge-pill {
    padding-right: 0.875em;
    padding-left: 0.875em;
    border-radius: 50rem;
  }
  .rounded-custom {
    border-radius: 1rem;
  }
  .ptb-100 {
    padding: 100px 0;
  }
  .pt-100 {
    padding-top: 100px;
  }
  .pt-165 {
    padding-top: 165px;
  }
  .pb-100 {
    padding-bottom: 100px;
  }
  .mt--165 {
    margin-top: -165px !important;
  }
  .gray-light-bg {
    background: #f8f9fa;
  }
  .primary-bg {
    background: #035ded;
  }
  .secondary-bg {
    background: #031974;
  }
  .color-primary {
    color: #035ded;
  }
  .color-secondary {
    color: #031974;
  }
  .gradient-bg {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#031974),
      to(#1934a7)
    ) !important;
    background: linear-gradient(-90deg, #031974, #1934a7) !important;
  }
  .white-bg {
    background: #ffffff;
  }
  a {
    color: #035ded;
  }
  a:hover {
    color: #031974;
    text-decoration: none;
  }
  .color-1 {
    color: #ff164e;
  }
  .color-1-bg {
    background: rgba(255, 22, 78, 0.15);
  }
  .color-2 {
    color: #031974;
  }
  .color-2-bg {
    background: rgba(145, 35, 255, 0.15);
  }
  .color-3 {
    color: #035ded;
  }
  .color-3-bg {
    background: rgba(0, 115, 236, 0.15);
  }
  .color-4,
  .ratting-color {
    color: #ff7c3f;
  }
  .color-4-bg {
    background: rgba(255, 124, 63, 0.15);
  }
  .color-5,
  .success-color {
    color: #2ebf6d;
  }
  .color-5-bg {
    background: rgba(46, 191, 109, 0.15);
  }
  .color-6 {
    color: #f25c7f;
  }
  .color-6-bg {
    background: rgba(242, 92, 127, 0.15);
  }
  .animated-btn {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .outline-btn,
  .secondary-solid-btn,
  .primary-solid-btn,
  .solid-white-btn,
  .outline-white-btn,
  .secondary-outline-btn {
    padding: 12px 28px;
    font-family: var(--font-family-primary);
    font-weight: 500;
    font-size: 14px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .primary-solid-btn {
    border: 1px solid #035ded;
    background: #035ded;
    color: #ffffff;
  }
  .primary-solid-btn:hover {
    border-color: #004bc3;
    background: #004bc3;
    color: #ffffff;
  }
  .secondary-solid-btn {
    color: #fff;
    background: #031974;
    border: 1px solid #031974;
  }
  .secondary-solid-btn:hover {
    background: transparent;
    color: #031974;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .secondary-outline-btn {
    color: #031974;
    display: inline-block;
    border: 1px solid #031974;
  }
  .secondary-outline-btn:hover {
    background: #031974;
    color: #ffffff;
  }
  .outline-btn {
    position: relative;
    display: inline-block;
    color: #035ded;
    border: 1px solid #035ded;
  }
  .outline-btn:hover {
    color: #ffffff;
    background: #035ded;
  }
  .primary-bg h1,
  .primary-bg p {
    color: #fff;
  }
  .solid-white-btn {
    background: #ffffff;
    border-color: #ffffff;
    color: #585b60;
  }
  .solid-white-btn:hover {
    background: transparent;
    color: #ffffff;
  }
  .outline-white-btn {
    color: #ffffff;
    border-color: #ffffff;
  }
  .outline-white-btn:hover {
    background: #ffffff;
    color: #585b60;
  }
  .icon-sm {
    font-size: 25px;
    line-height: 25px;
  }
  .icon-md {
    font-size: 35px;
    line-height: 35px;
  }
  .icon-lg {
    font-size: 45px;
    line-height: 45px;
  }
  .form-control {
    border-color: #ebebeb;
    padding: 0.75rem 0.85rem;
    height: calc(2em + 0.75rem + 2px);
  }
  .form-control:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  input[type]::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder,
  select::-webkit-input-placeholder,
  select.form-control.has-value {
    color: #b1b1b1 !important;
    font-size: 13px;
  }
  input[type]:-moz-placeholder,
  textarea:-moz-placeholder,
  select:-moz-placeholder,
  select.form-control.has-value {
    color: #b1b1b1;
    font-size: 13px;
  }
  input[type]::-moz-placeholder,
  textarea::-moz-placeholder,
  select::-moz-placeholder,
  select.form-control.has-value {
    color: #b1b1b1;
    font-size: 13px;
  }
  input[type]:-ms-input-placeholder,
  textarea:-ms-input-placeholder,
  select:-ms-input-placeholder,
  select.form-control.has-value {
    color: #b1b1b1;
    font-size: 13px;
  }
  select.form-control.has-value {
    height: 52px;
  }
  .section-heading h2 span {
    font-weight: 400;
    font-size: 2rem;
  }
  .z-index {
    z-index: 9;
  }
  .gradient-overlay {
    position: relative;
    width: 100%;
  }
  .gradient-overlay:before {
    position: absolute;
    content: "";
    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#031974),
      to(#1934a7)
    );
    background-image: linear-gradient(-90deg, #031974, #1934a7);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.8;
  }
  .height-lg-100vh {
    min-height: 100vh;
  }
  
  @media (max-width: 576px) {
    .height-lg-100vh {
      height: auto;
    }
  }
  .custom-badge {
    top: 5%;
    right: 8%;
  }
  .bg-transparent ul li a:hover,
  .white-bg.affix ul li a:hover {
    opacity: 1;
  }
  .bg-transparent.affix {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#031974),
      to(#1934a7)
    ) !important;
    background: linear-gradient(90deg, #031974, #1934a7) !important;
  }
  #header-top-bar {
    padding: 0.75rem 0;
    background: #f1f1f1;
  }
  .topbar-text ul {
    font-weight: 500;
    line-height: 0;
    margin: 0;
  }
  .topbar-text ul li a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #575757;
    font-size: 13px;
    line-height: 15px;
    font-family: var(--font-family-secondary);
  }
  .topbar-text ul li a:hover {
    color: #035ded;
  }
  .gradient-bg .topbar-text ul li,
  .gradient-bg .topbar-text ul li a {
    color: #ffffff;
  }
  .gradient-bg .topbar-text ul li a:hover {
    opacity: 1;
  }
  .topbar-text ul li span {
    font-size: 84%;
  }
  .header-main {
    position: relative;
    display: block;
  }
  .main-header-menu-wrap {
    display: block !important;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-name: slideInU;
    animation-name: slideInU;
  }
  .main-header-menu-wrap .navbar-toggler.btn:focus {
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #035ded;
  }
  .main-header-menu-wrap.affix {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-name: slideInDown;
    animation-name: slideInDown;
  }
  .header-nav {
    padding: 0;
  }
  
  @media (min-width: 320px) and (max-width: 767px) {
    .header-nav {
      padding: 0.5rem 0;
    }
  }
  .sub-menu-nav-link {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-family-primary);
    color: #575757;
  }
  .main-link-toggle::after {
    display: inline-block;
    font-family: "Font Awesome 6 Pro";
    content: "\f107";
    margin-left: 0.25rem;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
    position: relative;
    font-size: 11px;
    font-weight: 600;
  }
  .bg-transparent .main-navbar-nav li.header-nav-last-item a.btn {
    border-color: #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .bg-transparent .main-navbar-nav li.header-nav-last-item a.btn:hover {
    background: #035ded !important;
    color: #ffffff !important;
    border-color: #035ded;
  }
  .main-sub-menu {
    width: 100%;
    padding-left: 0;
    list-style: none;
  }
  .sub-menu-nav-group {
    padding-left: 0;
    list-style: none;
  }
  .u-header__navbar-link {
    color: #757575;
    font-size: 0.875rem;
    font-weight: 300;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .custom-nav-item:hover .custom-nav-link,
  .custom-nav-item:focus .custom-nav-link {
    color: #035ded;
  }
  .header-main .active > .custom-nav-link {
    color: #035ded;
  }
  .custom-nav-link {
    font-size: 14px;
    line-height: 16px;
    font-family: var(--font-family-primary);
    font-weight: 500;
    color: #575757;
    position: relative;
  }
  .bg-transparent .header-nav a.custom-nav-link,
  .gradient-bg .header-nav a.custom-nav-link {
    color: #ffffff !important;
    opacity: 0.8;
  }
  .bg-transparent .header-nav a.custom-nav-link:hover,
  .gradient-bg .header-nav a.custom-nav-link:hover {
    opacity: 1;
  }
  .headroom--not-top.slideDown .bg-transparent {
    background: #1934a7 !important;
  }
  
  @media (max-width: 767px) {
    .bg-transparent .header-nav #navBar.navbar-collapse,
    .gradient-bg .header-nav #navBar.navbar-collapse {
      background: #1934a7 !important;
    }
    #navBar.navbar-collapse {
      padding: 20px;
    }
    .bg-transparent .header-nav #navBar.navbar-collapse .navbar-nav .nav-link,
    .gradient-bg .header-nav #navBar.navbar-collapse .navbar-nav .nav-link {
      padding: 0.75rem 0;
    }
  }
  .bg-transparent button.navbar-toggler span {
    color: #ffffff;
  }
  .sub-link-toggle {
    position: relative;
  }
  .sub-link-toggle::after {
    display: inline-block;
    font-family: "themify";
    font-size: 60%;
    content: "\e649";
    margin-left: auto;
    margin-right: 0.04rem;
    -webkit-transition: 0.3s ease;
    transition: 0.3s ease;
  }
  .main-sub-menu .active > .sub-menu-nav-link {
    color: #035ded;
  }
  .sub-menu-title {
    display: block;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    font-family: var(--font-family-primary);
    color: #0d1536;
  }
  .hs-has-sub-menu {
    position: relative;
  }
  .navbar-expand {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .navbar-expand .main-navbar-nav {
    -ms-flex-align: center;
    -webkit-box-align: center;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0;
  }
  .navbar-expand .main-navbar-nav .header-nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0;
  }
  .header-nav-last-item .btn {
    padding: 10px 20px;
    font-size: 14px;
  }
  .navbar-expand .main-navbar-nav .custom-nav-link {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0.875rem;
    padding-left: 0.875rem;
  }
  .navbar-expand .main-navbar-nav .sub-menu-nav-link {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
  .navbar-expand .main-navbar-nav .sub-menu-nav-group .sub-menu-nav-link {
    padding-left: 0;
  }
  .navbar-expand .main-navbar-nav .main-sub-menu.hs-sub-menu {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .navbar-expand .main-navbar-nav .main-sub-menu .hs-has-sub-menu {
    padding: 0;
  }
  .navbar-expand .main-navbar-nav .main-sub-menu .hs-has-sub-menu .main-sub-menu {
    margin-top: -1.1875rem;
  }
  .navbar-expand
    .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%;
  }
  .navbar-expand .mega-menu-wrap {
    padding: 2.5rem;
    margin-left: 0;
    margin-right: 0;
  }
  .navbar-expand .u-header__promo {
    border-bottom-right-radius: 0.25rem;
  }
  .navbar-expand .u-header__promo-card {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex: 1 0 0%;
    -webkit-box-flex: 1;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }
  .navbar-expand .u-header__promo-card-deck {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-flow: row wrap;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
  }
  .navbar-expand
    .u-header__promo-card-deck
    .title-with-icon-item:not(:last-child)
    .title-with-icon-link {
    position: relative;
  }
  .navbar-expand
    .u-header__promo-card-deck
    .title-with-icon-item:not(:last-child)
    .title-with-icon-link::after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 1px solid #ebebeb;
    height: 100%;
    content: "";
  }
  .main-sub-menu {
    width: 100%;
    padding-left: 0;
    list-style: none;
  }
  
  @media (min-width: 320px) and (max-width: 767px) {
    .main-sub-menu {
      height: 220px;
      overflow-y: scroll;
    }
    .menu-titile-icon {
      display: none;
    }
  }
  .hs-mega-menu-opened a.custom-nav-link:before,
  .hs-sub-menu-opened a.custom-nav-link:before {
    content: "";
    position: absolute;
    bottom: -11px;
    left: 50%;
    width: 20px;
    height: 20px;
    -webkit-transform: translate(-50%, 0) rotate(-315deg);
    transform: translate(-50%, 0) rotate(-315deg);
    border-radius: 3px;
    background-color: #035ded;
  }
  .bg-transparent .hs-mega-menu-opened a.custom-nav-link:before,
  .bg-transparent .hs-sub-menu-opened a.custom-nav-link:before {
    background: #ffffff;
  }
  
  @media (max-width: 767.98px) {
    .hs-mega-menu-opened a.custom-nav-link:before,
    .hs-sub-menu-opened a.custom-nav-link:before,
    .bg-transparent .hs-mega-menu-opened a.custom-nav-link:before,
    .bg-transparent .hs-sub-menu-opened a.custom-nav-link:before {
      display: none;
    }
  }
  @media (min-width: 768px) {
    .navbar-expand-md .main-navbar-nav .custom-nav-link {
      padding-top: 1.75rem;
      padding-bottom: 1.75rem;
      padding-right: 0.875rem;
      padding-left: 0.875rem;
    }
    .navbar-expand-md .main-navbar-nav .main-sub-menu {
      border-top: 2px solid #035ded;
      border-radius: 0.5rem;
      -webkit-box-shadow: 0 12px 20px 0 rgba(23, 28, 33, 0.16);
      box-shadow: 0 12px 20px 0 rgba(23, 28, 33, 0.16);
    }
    .bg-transparent .navbar-expand-md .main-navbar-nav .main-sub-menu {
      border-top: 2px solid #ffffff;
    }
    .navbar-expand-md
      .hs-has-sub-menu
      ul.hs-sub-menu
      > .hs-has-sub-menu
      ul.hs-sub-menu {
      border-top-right-radius: 0.25rem;
    }
    .navbar-expand-md .main-navbar-nav .main-sub-menu.hs-sub-menu {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .navbar-expand-md
      .main-navbar-nav
      .main-sub-menu
      .hs-has-sub-menu
      .main-sub-menu {
      margin-top: -1.1875rem;
    }
    .navbar-expand-md .main-navbar-nav .main-sub-menu.hs-sub-menu {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .navbar-expand-md .main-navbar-nav .main-sub-menu .hs-has-sub-menu {
      padding: 0;
    }
    .navbar-expand-md .main-navbar-nav .sub-menu-nav-link {
      padding-right: 1.75rem;
      padding-left: 1.75rem;
    }
    .navbar-expand-md .main-navbar-nav .header-nav-last-item {
      text-align: right;
      padding-left: 1rem;
      padding-right: 0;
    }
    .navbar-expand-md .mega-menu-wrap {
      padding: 2.5rem;
      margin-left: 0;
      margin-right: 0;
    }
    .navbar-expand-md .main-navbar-nav .sub-menu-nav-group .sub-menu-nav-link {
      padding-left: 0;
    }
    .navbar-expand-md .u-header__promo {
      border-bottom-right-radius: 0.25rem;
    }
  }
  .menu-banner-wrap {
    position: relative;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    border-radius: 0.5rem;
  }
  .menu-banner-content {
    z-index: 1;
    width: 100%;
    padding: 2rem 3rem;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
  }
  .u-header__product-banner {
    background-color: #f5f5f5;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    padding-right: 1.5rem;
  }
  .u-header__product-banner-content {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .u-header__promo {
    background-color: #f5f5f5;
  }
  .u-header__promo-inner {
    padding: 2rem;
  }
  .menu-countdown h2 {
    font-size: 25px;
    line-height: 20px;
    margin-bottom: 0;
    opacity: 0.8;
    color: #ffffff;
  }
  .clock.menu-countdown h6 {
    font-size: 14px;
    line-height: 18px;
    color: #ffffff !important;
  }
  .offer-countdown-item h2 {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 0;
    color: #ffffff;
  }
  .offer-countdown-item h6 {
    font-size: 16px;
    line-height: 18px;
    opacity: 0.8;
    color: #ffffff;
    font-weight: 500;
  }
  .title-with-icon-item ~ .title-with-icon-item {
    border-top: 1px solid #ebebeb;
  }
  .title-with-icon-item {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .title-with-icon-link {
    display: block;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    color: #464646;
  }
  .title-with-icon-link:hover .u-header__promo-title {
    color: #035ded;
  }
  .menu-titile-icon {
    width: 100%;
    max-width: 2rem;
    margin-left: 1rem;
  }
  .menu-item-icon {
    margin-left: 1rem;
  }
  .menu-item-icon i {
    font-size: 20px;
    color: #035ded;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .title-with-icon-link:hover .menu-item-icon i {
    color: #0e6bff;
  }
  .u-header__promo-title {
    display: block;
    font-weight: 600;
    font-family: var(--font-family-primary);
    font-size: 14px;
    line-height: 24px;
  }
  .u-header__promo-text {
    display: block;
    color: #696a6e;
    font-size: 13px;
    font-weight: 500;
  }
  .u-header__promo-footer {
    background-color: #f5f5f5;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .u-header__promo-footer-ver-divider {
    position: relative;
  }
  .u-header__promo-footer-item {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .u-header__promo-footer-item small,
  .u-header__promo-footer-item a {
    font-family: var(--font-family-primary);
  }
  .u-header__promo-footer-item small {
    font-weight: 500;
  }
  .u-header__promo-footer-item a {
    font-weight: 600;
  }
  a.video-play-icon {
    border-radius: 50%;
    display: block;
    -webkit-animation: ripple 1s linear infinite;
    animation: ripple 1s linear infinite;
    -webkit-transition: 0.6s;
  }
  .video-play-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 50%;
    position: relative;
    z-index: 4;
    display: inline-block;
    border: 2px solid #ebebeb;
    cursor: pointer;
    background: #035ded;
  }
  .video-play-icon span {
    position: absolute;
    text-align: center;
    width: 100%;
    top: 18%;
    font-size: 15px;
    left: 1px;
    color: #ffffff;
  }
  @media (max-width: 767.98px) {
    .navbar-expand-md .custom-nav-link,
    .navbar-expand-md .sub-menu-nav-link {
      display: -ms-flexbox;
      display: -webkit-box;
      display: flex;
      -ms-flex-align: center;
      -webkit-box-align: center;
      align-items: center;
    }
    .navbar-expand-md .main-link-toggle::after,
    .navbar-expand-md .sub-link-toggle::after {
      margin-right: auto;
    }
    .navbar-expand-md .hs-mega-menu-opened .custom-nav-link-toggle::after,
    .navbar-expand-md .hs-sub-menu-opened .custom-nav-link-toggle::after {
      -webkit-transform: rotate(-180deg);
      transform: rotate(-180deg);
    }
    .navbar-expand-md .hs-sub-menu-opened > .sub-link-toggle::after {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
    .u-header--bg-transparent .main-navbar-nav {
      padding: 0.875rem 0;
      padding-top: 1rem;
    }
    .navbar-expand-md .sub-menu-title {
      padding-right: 1.5rem;
      margin-bottom: 0;
    }
    .u-header--bg-transparent .main-navbar-nav {
      background-color: #fff;
    }
    .navbar-expand-md .sub-menu-nav-group {
      padding-top: 0.5rem;
      padding-right: 1.5rem;
    }
    .navbar-expand-md .main-sub-menu:not(.u-header__promo) {
      border-right: 2px solid #035ded;
    }
    .navbar-expand-md .main-sub-menu {
      min-width: 100% !important;
    }
    .navbar-expand-md .main-sub-menu .hs-has-sub-menu {
      padding-right: 1.5rem;
      padding-top: 0;
      padding-bottom: 0;
    }
    .navbar-expand-md .main-sub-menu .main-sub-menu,
    .main-sub-menu .submenu-item {
      padding-right: 1.5rem;
    }
  }
  @-webkit-keyframes slideDown {
    0% {
      -webkit-transform: translateY(-100%);
    }
    100% {
      -webkit-transform: translateY(0);
    }
  }
  
  @keyframes slideDown {
    0% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }
  .animated.slideDown {
    -webkit-animation-name: slideDown;
    animation-name: slideDown;
  }
  
  @-webkit-keyframes slideUp {
    0% {
      -webkit-transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100%);
    }
  }
  
  @keyframes slideUp {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }
  }
  .animated.slideUp {
    -webkit-animation-name: slideUp;
    animation-name: slideUp;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
  }
  .headroom {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    will-change: transform;
    -webkit-transition: -webkit-transform 0.3s linear;
    transition: -webkit-transform 0.3s linear;
    transition: transform 0.3s linear;
    transition: transform 0.3s linear, -webkit-transform 0.3s linear;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .headroom--pinned {
    display: block;
  }
  .headroom--unpinned {
    display: none;
  }
  .headroom--pinned {
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  .headroom--unpinned {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  
  @media (min-width: 320px) and (max-width: 992px) {
    .navbar-expand-md .main-navbar-nav .custom-nav-link {
      padding-right: 0.12rem;
      padding-left: 0.12rem;
    }
    .ptb-100 {
      padding: 55px 0;
    }
    .pt-100 {
      padding-top: 55px;
    }
    .pb-100 {
      padding-bottom: 55px;
    }
    .pt-165 {
      padding-top: 100px;
    }
  }
  
  @media (min-width: 320px) and (max-width: 768px) {
    .main-with-padding {
      padding-top: 92px !important;
    }
    .main-without-padding {
      padding-top: 36px !important;
    }
    .main-without-topbar {
      padding-top: 0 !important;
    }
  }
  #hero-shap-1 {
    top: 0;
  }
  svg {
    overflow: hidden;
  }
  svg:root {
    width: 100%;
    height: 100%;
  }
  svg.shape-bg,
  svg.promo-bg {
    position: absolute;
    width: 100%;
    left: 0;
    z-index: -1;
  }
  .hero-image {
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
  }
  #promo-shape {
    top: 3.5%;
    width: 100%;
    right: 0;
    transform: scaleX(-1);
  }
  .main-with-padding {
    padding-top: 105px;
  }
  .hero-equal-height {
    position: relative;
    min-height: 660px;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  .fit-cover {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .z--1 {
    z-index: -1;
  }
  .animation-image-wrap {
    position: relative;
  }
  .animation-icon-img {
    position: absolute;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-animation: bounce 1s ease-in-out 0s infinite alternate;
    animation: bounce 1s ease-in-out 0s infinite alternate;
  }
  .animation-icon-img.animation-icon-1 {
    left: 15%;
    top: 30%;
  }
  .animation-icon-img.animation-icon-2 {
    left: inherit;
    right: 45%;
    top: 0;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
  }
  .animation-icon-img.animation-icon-3 {
    top: 30%;
    left: 75%;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  .animation-icon-img.animation-icon-4 {
    left: inherit;
    right: 45%;
    top: 60%;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
  }
  .animation-icon-img.animation-icon-5 {
    left: 14%;
    top: 55%;
  }
  
  @-webkit-keyframes bounce {
    0% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    to {
      -webkit-transform: translateY(-25px);
      transform: translateY(-25px);
    }
  }
  
  @keyframes bounce {
    0% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
    }
    to {
      -webkit-transform: translateY(-25px);
      transform: translateY(-25px);
    }
  }
  .two-col-list {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -js-display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .two-col-list li {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 50%;
    flex: 1 1 50%;
    max-width: 50%;
    padding: 0 12px 8px 0;
    font-size: 14px;
    display: -ms-inline-flexbox;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  
  @media screen and (max-width: 768px) and (min-width: 320px) {
    .two-col-list li {
      max-width: 100%;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
      flex: 1 1 100%;
    }
  }
  .shape-bottom img.bottom-shape {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    margin: 0 auto;
  }
  .offer-countdown {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 20px;
    color: #fff;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .left-arrow,
  .right-arrow {
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-bottom: 20px solid transparent;
  }
  .left-arrow {
    border-left: 20px solid #fff;
    margin-right: 25px;
  }
  .right-arrow {
    border-right: 20px solid #fff;
    margin-left: 25px;
  }
  .background-video-overly {
    background-image: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgba(3, 25, 116, 0.45)),
      to(rgba(25, 52, 167, 0.4))
    );
    background-image: linear-gradient(
      90deg,
      rgba(3, 25, 116, 0.45),
      rgba(25, 52, 167, 0.4)
    );
  }
  .hero-slider-section {
    position: relative;
    overflow: hidden;
  }
  .gradient-overly-right,
  .gradient-overly-left,
  .gradient-overly-bottom,
  .gradient-overly-top {
    z-index: 1;
    width: 100%;
    min-width: 100%;
    position: relative;
    overflow: hidden;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
  }
  .gradient-overly-right:before,
  .gradient-overly-left:before,
  .gradient-overly-bottom:before,
  .gradient-overly-top:before {
    content: "";
    z-index: -1;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .gradient-overly-right:before {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#031974),
      to(rgba(25, 52, 167, 0.5))
    );
    background: linear-gradient(90deg, #031974, rgba(25, 52, 167, 0.5));
  }
  .gradient-overly-left:before {
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(rgba(3, 25, 116, 0.5)),
      to(#1934a7)
    );
    background: linear-gradient(90deg, rgba(3, 25, 116, 0.5), #1934a7);
  }
  .gradient-overly-bottom:before {
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(3, 25, 116, 0.5)),
      to(rgba(25, 52, 167, 0.85))
    );
    background: linear-gradient(
      to bottom,
      rgba(3, 25, 116, 0.5),
      rgba(25, 52, 167, 0.85)
    );
  }
  .gradient-overly-top:before {
    background: -webkit-gradient(
      linear,
      left bottom,
      left top,
      from(rgba(3, 25, 116, 0.85)),
      to(rgba(25, 52, 167, 0.5))
    );
    background: linear-gradient(
      to top,
      rgba(3, 25, 116, 0.85),
      rgba(25, 52, 167, 0.5)
    );
  }
  .header-content {
    position: relative;
    padding: 0 50px 50px 0;
    overflow: hidden;
  }
  .header-content .line {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    z-index: -1;
    position: absolute;
    border: 2px dashed #ebebeb;
    -webkit-clip-path: polygon(100% 0%, 100% 0%, 35% 100%, 100% 100%);
    clip-path: polygon(100% 0%, 100% 0%, 35% 100%, 100% 100%);
  }
  .hero-slider-one .owl-item.active h1 {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .hero-slider-one .owl-item.active .cros-animation {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .hero-slider-one .owl-item.active .action-btns {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInUp;
    animation-name: fadeInUp;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .hero-slider-one .owl-item.active .line {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-name: fadeInLeft;
    animation-name: fadeInLeft;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }
  .hero-slider-one
    .owl-carousel.effects
    .cros-animation[data-delay-transtion="1"] {
    -webkit-transition-delay: 100ms;
    transition-delay: 100ms;
  }
  .hero-slider-one .owl-carousel .cros-animation {
    -webkit-transform: translateY(70%);
    transform: translateY(70%);
    filter: alpha(opacity=0);
    -webkit-transition: all 1.3s;
    transition: all 1.3s;
    -webkit-transition-property: transform, opacity;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
  }
  .hero-slider-one.owl-carousel .owl-item .domain-search-list li a img,
  .hero-content-slider.owl-carousel .owl-item .domain-search-list li a img {
    width: 65px !important;
    margin-bottom: 5px;
  }
  .custom-dot.owl-theme .owl-dots {
    position: absolute;
  }
  .custom-dot.dot-right-center.owl-theme .owl-dots {
    margin: 0 !important;
    top: 40%;
    right: 10%;
  }
  .custom-dot.dot-bottom-center.owl-theme .owl-dots {
    width: 100%;
    margin: 10px auto;
  }
  .custom-dot.dot-bottom-center.owl-theme .owl-dots button {
    margin: 0 4px;
    border: 1px solid #035ded;
  }
  .custom-dot.dot-right-center.owl-theme .owl-dots button {
    display: block;
    border: 1px solid #ebebeb;
    margin: 6px 0;
  }
  .custom-dot.custom-dot-2.owl-theme .owl-dots button {
    margin: 0 4px;
    border: 1px solid #ebebeb;
  }
  .custom-dot.custom-dot-2.owl-theme .owl-dots {
    left: 15px;
    top: auto;
    bottom: -10%;
  }
  .custom-dot.owl-theme .owl-dots button {
    border-radius: 100%;
  }
  .custom-dot.owl-theme .owl-dots button:focus {
    outline: none;
  }
  .custom-dot.owl-theme .owl-dots button.owl-dot.active,
  .custom-dot.owl-theme .owl-dots button.owl-dot span {
    background: #ffffff;
  }
  .custom-dot.owl-theme .owl-dots button.owl-dot span:hover {
    background: #035ded;
  }
  .custom-dot.owl-theme .owl-dots button.owl-dot.active span {
    background: #035ded;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
  }
  .custom-dot.owl-theme .owl-dots .owl-dot span {
    margin: 2px;
  }
  .gradient-bg-overly {
    background: linear-gradient(
      75deg,
      rgba(0, 115, 236, 0.14) 10%,
      rgba(113, 0, 185, 0.24)
    ) !important;
  }
  .offer-box {
    max-width: 300px;
    width: auto;
    border-radius: 1rem;
    margin: 0 auto;
    padding: 15px;
    background: rgba(26, 42, 112, 0.65);
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .offer-box:hover {
    background: rgba(3, 25, 116, 0.75);
  }
  .offer-box .offer-price {
    font-family: var(--font-family-primary);
    font-size: 120px;
    line-height: 1;
    font-weight: 600;
    position: relative;
  }
  .offer-box .offer-price .currency {
    font-size: 60px;
    line-height: 1;
    vertical-align: super;
    margin-top: -60px;
    display: inline;
  }
  .offer-box .offer-price .decimal {
    font-size: 60px;
    line-height: 1;
    vertical-align: super;
    display: inline;
  }
  .offer-box .offer-price small {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    margin-right: -80px;
    margin-top: -20px;
    display: block;
  }
  .custom-plan-box {
    position: relative;
    width: 100%;
    display: block;
    padding: 70px 45px 50px 45px;
  }
  .single-vps-item {
    width: 100%;
    display: block;
    padding: 15px;
    min-width: 150px;
    border: 1px dashed #ebebeb;
  }
  .single-vps-item h6 {
    font-weight: 600;
  }
  .single-vps-item h5 {
    font-weight: 700;
    font-size: 16px;
  }
  
  @media (min-width: 320px) and (max-width: 767px) {
    .custom-vps-list li {
      width: 45%;
      display: inline-block;
      margin-bottom: 10px;
    }
    .single-vps-item {
      padding: 12px 5px;
    }
  }
  
  @media (min-width: 320px) and (max-width: 576px) {
    .custom-vps-list li {
      width: 100%;
    }
    .custom-vps-list li.list-inline-item:not(:last-child) {
      margin-right: 0;
    }
  }
  .custom-plan-box .slider .tooltip {
    font-family: var(--font-family-primary);
    font-size: 14px;
    font-weight: 700;
  }
  .custom-plan-box .slider .tooltip.top {
    margin-top: 22px;
  }
  .custom-plan-box .slider .tooltip.top .tooltip-arrow {
    border: 0;
  }
  .tooltip.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
  }
  .tooltip.top {
    padding: 5px 0;
    margin-top: -3px;
  }
  .tooltip.in {
    filter: alpha(opacity=90);
    opacity: 0.9;
  }
  .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
  }
  .custom-plan-box .slider .tooltip.top .tooltip-inner {
    color: #757575;
    text-align: center;
    background-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .custom-plan-box .slider .tooltip.top .tooltip-inner:after {
    margin-left: 2px;
  }
  .tooltip-inner[data-unit]:after {
    position: absolute;
    content: attr(data-unit);
    z-index: 99;
  }
  .custom-plan-box .slider .tooltip-up {
    font-size: 21px;
    font-weight: 700;
    color: #035ded;
    text-align: center;
    margin-top: -95px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .custom-plan-box .slider .tooltip-up:before {
    content: attr(data-currency);
    color: #035ded;
  }
  .custom-plan-box .slider.slider-horizontal {
    width: 100%;
  }
  .custom-plan-box .slider.slider-horizontal .slider-track {
    height: 10px;
  }
  .custom-plan-box .slider-selection {
    background: none;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #035ded;
  }
  .custom-plan-box .slider-track {
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #ebebeb;
  }
  .custom-plan-box .slider-track:before {
    position: absolute;
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #035ded;
    left: -8px;
    top: 50%;
    margin-top: -9px;
  }
  .custom-plan-box .slider-track:after {
    position: absolute;
    content: "";
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #035ded;
    right: -8px;
    top: 50%;
    margin-top: -9px;
  }
  .custom-plan-box .slider-handle {
    position: absolute;
    top: -6px;
    width: 30px;
    height: 30px;
    background: none;
    background-color: #ffffff;
    -webkit-filter: none;
    -moz-filter: none;
    -ms-filter: none;
    filter: none;
    border: 8px solid #035ded;
    outline: none;
    cursor: pointer;
    -webkit-transition: background-color 0.3s ease, border-color 0.3s ease;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }
  .custom-plan-box .slider-handle:hover,
  .custom-plan-box .slider-handle:focus {
    background-color: #ebebeb;
    border: 8px solid #035ded;
  }
  .custom-plan-info-box .price {
    font-family: var(--font-family-primary);
    font-size: 30px;
    font-weight: 700;
    color: #035ded;
    margin-bottom: 20px;
    line-height: 43px;
  }
  .custom-plan-info-box .btn-holder a {
    margin: 0;
  }
  .card.single-pricing-pack {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: 0 0 0 1px #ebebeb;
    box-shadow: 0 0 0 1px #ebebeb;
    border-radius: 1rem !important;
    border: 2px solid transparent;
  }
  .card.single-pricing-pack:hover {
    z-index: 2;
  }
  .single-pricing-pack:hover {
    -webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.12) !important;
    border: 2px solid #035ded;
  }
  .single-pricing-pack .card-body {
    color: #757575;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.5rem;
  }
  .single-pricing-pack .card-body.px-5 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .pricing-header {
    position: relative;
    background: transparent;
  }
  .pricing-header .price {
    font-size: 35px;
    font-weight: 700;
    font-family: var(--font-family-primary);
    color: #035ded;
  }
  .pricing-header .price span {
    font-size: 14px;
    margin-left: 4px;
    font-weight: 500;
  }
  .pricing-header::after {
    content: "";
    display: block;
    width: 80%;
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -40%;
    height: 1px;
    background: radial-gradient(
      at center center,
      #dee4ef 0px,
      rgba(255, 255, 255, 0) 75%
    );
  }
  .pricing-feature-list li {
    font-size: 15px;
    line-height: 30px;
  }
  .pricing-feature-list li i {
    float: right;
    line-height: inherit;
    vertical-align: middle;
    font-size: 14px;
  }
  .single-pricing-pack li.del {
    opacity: 0.7;
  }
  .pricing-feature-list li span {
    font-weight: 700;
    font-family: var(--font-family-primary);
    color: #5a5a5a;
    font-size: 13px;
  }
  .popular-price {
    -webkit-box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125) !important;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125) !important;
    border: 2px solid #035ded !important;
  }
  .popular-price:hover {
    -webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.12) !important;
  }
  .yearly-price {
    display: none;
  }
  .switch-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .form-switch {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .form-switch span {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    font-family: var(--font-family-primary);
  }
  .form-switch .switch-icon {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 30px;
    border: 1px solid #ebebeb;
    border-radius: 15px;
    -webkit-transition: all 0.3s linear;
    transition: all 0.3s linear;
    margin: 0 15px;
    background: #ffffff;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  .form-switch .switch-icon::after {
    content: "";
    position: absolute;
    left: 0;
    width: 30px;
    height: 22px;
    background-color: #035ded;
    border-radius: 15px;
    -webkit-transform: translate3d(4px, 3px, 0);
    transform: translate3d(4px, 3px, 0);
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .form-switch input {
    display: none;
  }
  .form-switch input:checked + .switch-icon::after {
    -webkit-transform: translate3d(29px, 3px, 0);
    transform: translate3d(29px, 3px, 0);
  }
  .form-switch .beforeinput.text-success,
  .form-switch .afterinput.text-success {
    color: #035ded !important;
  }
  .table-bordered.comparision-table thead td,
  .comparision-table.table-bordered thead th {
    border-bottom-width: 1px;
  }
  .comparision-price {
    font-weight: 700;
  }
  .comparision-price span {
    font-size: 14px;
    opacity: 0.7;
    font-weight: 400;
  }
  .comparision-table-body tr > td {
    font-size: 13px;
    padding: 6px;
    min-width: 180px;
    vertical-align: middle;
  }
  .comparision-table-body tr > td p {
    padding-left: 10px;
    margin-bottom: 0;
  }
  td.td-blank {
    border: none !important;
  }
  .provider-logo img {
    display: inline-block;
    max-width: 100px;
    min-height: 13px;
    max-height: 34px;
  }
  .staff-member {
    position: relative;
    display: block;
  }
  .staff-member img {
    width: 100%;
  }
  .staff-member h5,
  .staff-member p,
  .staff-member span {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 1;
  }
  .staff-member h5,
  .staff-member p,
  .staff-member span {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 1;
  }
  .staff-member span {
    font-size: 0.9rem;
    font-weight: 400;
    color: #777;
    display: inline-block;
  }
  .social li a:hover span {
    color: #031974;
  }
  .staff-member .overlay {
    background: #1934a7;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(#031974),
      to(rgba(25, 52, 167, 0.85))
    );
    background: linear-gradient(90deg, #031974, rgba(25, 52, 167, 0.85));
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 40px 30px;
    text-align: center;
    opacity: 0;
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    border-radius: 4px;
  }
  .staff-member .overlay .teacher-quote {
    font-size: 0.9rem;
    font-weight: 300;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0;
  }
  .staff-member .overlay .teacher-name {
    color: #fff;
    text-decoration: none !important;
    display: block;
    margin-top: 15px;
  }
  .staff-member .overlay h5 {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-transition-delay: 0.1s;
    transition-delay: 0.1s;
    opacity: 0;
  }
  .staff-member .overlay .teacher-field {
    font-size: 0.9rem;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    display: inline-block;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
    opacity: 0;
  }
  .staff-member .overlay ul.social {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    opacity: 0;
  }
  .staff-member .overlay ul.social li {
    margin: 0;
  }
  .staff-member .overlay ul.social a {
    margin: 0 5px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: #fff;
    text-align: center;
    border-radius: 50%;
    display: block;
  }
  .staff-member .overlay ul.social {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all 0.4s;
    transition: all 0.4s;
    opacity: 0;
  }
  .staff-member:hover .social {
    -webkit-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }
  .staff-member .overlay .teacher-see-profile {
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    opacity: 0;
  }
  .staff-member:hover .overlay {
    opacity: 0.92;
  }
  .staff-member:hover .overlay h5,
  .staff-member:hover .overlay p,
  .staff-member:hover .overlay span,
  .staff-member:hover .overlay ul.social {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
  .team-social-list li a {
    padding: 5px;
  }
  .team-social-list li span {
    font-size: 18px;
    line-height: inherit;
    vertical-align: middle;
  }
  .overlay-opacity-gradient {
    position: absolute;
    width: 100%;
    height: 55px;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      from(white),
      color-stop(10%, rgba(255, 255, 255, 0)),
      color-stop(90%, rgba(255, 255, 255, 0)),
      color-stop(95%, white)
    );
    background: linear-gradient(
      to right,
      white,
      rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 0) 90%,
      white 95%
    );
    z-index: 100;
    left: 0;
    right: 0;
  }
  .single-client {
    display: -ms-grid;
    display: grid;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-grid-column-align: center;
    justify-items: center;
    width: 60%;
    height: auto;
    -webkit-transition: -webkit-transform 0.5s ease-in;
    transition: -webkit-transform 0.5s ease-in;
    transition: transform 0.5s ease-in;
    transition: transform 0.5s ease-in, -webkit-transform 0.5s ease-in;
    margin: 8px auto;
  }
  .single-client img {
    max-width: 100%;
    max-height: 100%;
    -webkit-filter: opacity(50%);
    filter: opacity(90%);
    width: 100%;
  }
  .single-client:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
  }
  .single-client:hover .client-img {
    -webkit-filter: none;
    filter: none;
    opacity: 1;
  }
  .accordion-faq .card {
    border-radius: 0;
    border: 0;
  }
  .accordion-faq .card-header {
    padding: 20px 0;
    background: transparent;
    border-bottom: 1px solid #ebebeb;
    cursor: pointer;
    margin-bottom: 0 !important;
    color: inherit;
  }
  .accordion-faq .card-header h6 {
    font-family: var(--font-family-primary);
    font-weight: 600;
  }
  .accordion-faq .card-body {
    padding: 25px 0;
    background: transparent;
  }
  .accordion-faq .card-header.collapsed:after {
    content: "\f067";
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .accordion-faq .card-header:after {
    font-family: "Font Awesome 6 Pro";
    content: "\f068";
    float: right;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    font-weight: 600;
  }
  @media (min-width: 320px) and (max-width: 992px) {
    .promo-top.mt--165 {
      margin-top: 0 !important;
    }
  }
  .single-promo-hover {
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    will-change: transform;
  }
  .single-promo-hover:hover {
    -webkit-box-shadow: 2px 10px 20px 0 rgba(2, 19, 79, 0.1) !important;
    box-shadow: 2px 10px 20px 0 rgba(2, 19, 79, 0.1) !important;
  }
  a .single-promo-2 p {
    color: #707070;
  }
  .circle-icon {
    display: block;
    margin-bottom: 30px;
  }
  .single-promo-2 span {
    border-radius: 0.25rem;
    font-size: 28px;
    width: 64px;
    height: 64px;
    line-height: 64px;
    background: #1934a7;
  }
  .popular-categories-list li {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
    margin-bottom: 25px;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }
  .popular-categories-list li:last-child {
    margin-bottom: 0;
  }
  .help-center-box-link {
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    left: -50px;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }
  .popular-categories-list li:hover {
    background: #f5f5f5;
  }
  .popular-categories-list li:hover .help-center-box-link {
    left: 20px;
  }
  .features-box {
    position: relative;
    display: block;
  }
  .features-box-icon {
    display: table-cell;
    vertical-align: top;
  }
  .features-box-content {
    display: table-cell;
    padding-left: 20px;
  }
  .tech-feature-list li {
    font-weight: 450;
    font-size: 14px;
  }
  .start-website-box {
    position: relative;
    z-index: 2;
  }
  .start-website-box div[class*="box"] {
    border-radius: 4px;
    border: 1px solid rgba(54, 57, 60, 0.1);
    -webkit-box-shadow: 0 4px 8px rgba(81, 85, 88, 0.1);
    box-shadow: 0 4px 8px rgba(81, 85, 88, 0.1);
  }
  .start-website-box div.left-box {
    padding: 60px 90px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  }
  .start-website-box div.right-box {
    padding: 60px 90px;
    color: #fff;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
  .start-website-box ul li h5 {
    margin-bottom: 0;
  }
  .start-website-box ul li {
    margin-bottom: 20px;
  }
  .start-website-box ul li p {
    font-size: 14px;
  }
  
  @media screen and (max-width: 991px) {
    .start-website-box div.left-box {
      padding: 40px 40px 50px;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0;
      border-right: 1px solid rgba(54, 57, 60, 0.1);
    }
    .start-website-box div.right-box {
      padding: 50px 40px 40px;
      border-bottom-left-radius: 4px;
      border-top-right-radius: 0;
      border-left: 1px solid rgba(54, 57, 60, 0.1);
    }
  }
  .domain-list {
    position: relative;
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    grid-auto-flow: dense;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
  .custom-shadow {
    -webkit-box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 1px 30px 0 rgba(0, 0, 0, 0.05);
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    border-radius: 1rem !important;
  }
  .custom-shadow:hover {
    -webkit-transform: translateY(-5px);
    transform: translateY(-5px);
    -webkit-box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125) !important;
    box-shadow: 0 1rem 3rem rgba(31, 45, 61, 0.125) !important;
  }
  .domain-list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    text-align: center;
    margin: 20px;
    border-radius: 1rem;
    border: 1px solid #ebebeb;
  }
  .domain-list li a {
    padding: 15px;
  }
  
  @media (min-width: 320px) and (max-width: 1199px) {
    .domain-list li {
      margin-bottom: 0 !important;
    }
  }
  .single-domain img {
    display: inline-block;
    max-width: 45px;
    min-height: 13px;
    max-height: 34px;
    margin-bottom: 10px;
  }
  .domain-price h6 {
    font-size: 16px;
    font-weight: 700;
    font-family: var(--font-family-primary);
  }
  .domain-price h6 span.badge {
    font-size: 10px;
    padding: 1px 4px;
  }
  .old-price {
    color: #757575;
    font-size: 14px;
    font-weight: 600;
    font-family: var(--font-family-primary);
    opacity: 0.7;
    text-decoration: line-through;
  }
  .feature-tab li {
    border: 1px solid #f5f5f5;
    border-left: 0 !important;
  }
  .feature-tab li:last-child {
    border-left: 1px solid #ebebeb !important;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .feature-tab li:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .feature-tab li a {
    padding: 12px 28px;
  }
  .feature-tab li a h6 {
    font-size: 14px;
  }
  .feature-tab li a.nav-link {
    border: 0;
    font-family: var(--font-family-primary);
    font-weight: 500;
    color: #333333;
    margin-bottom: 0;
  }
  .feature-tab li a p {
    display: block;
  }
  ul.disc-style {
    list-style-type: disc;
    padding: 0 0 0 18px;
  }
  ul.disc-style li {
    font-size: 14px;
    margin-bottom: 10px;
    list-style: disc;
  }
  .feature-new-tab li {
    border: 1px solid #035ded;
  }
  .feature-new-tab li:last-child {
    border-right: 1px solid #035ded !important;
  }
  .feature-new-tab li a {
    padding: 10px 15px;
    font-size: 13px;
  }
  .feature-new-tab li.nav-item.show .nav-link,
  .feature-new-tab .nav-link.active {
    background: #035ded;
    border: transparent;
    border-radius: 0;
  }
  .feature-new-tab li.nav-item.show .nav-link h6,
  .feature-new-tab .nav-link.active h6 {
    color: #ffffff;
  }
  
  @media (min-width: 320px) and (max-width: 767px) {
    .feature-new-tab {
      margin-bottom: 3rem !important;
    }
  }
  .single-popular-domain img {
    display: inline-block;
    max-width: 100px;
    min-height: 13px;
    max-height: 34px;
    margin-bottom: 20px;
  }
  .single-popular-domain span.badge {
    left: 10%;
    top: 16%;
  }
  .domain-price-list .domain-old-price {
    font-size: 15px;
    font-weight: 600;
    opacity: 0.7;
  }
  .domain-price-list .domain-new-price {
    font-size: 22px;
    line-height: initial;
    font-family: var(--font-family-primary);
    font-weight: 700;
  }
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: #fff;
  }
  .loader1 {
    display: block;
    position: relative;
    right: 45%;
    top: 50%;
  }
  .loader1 span {
    vertical-align: middle;
    border-radius: 100%;
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 3px 2px;
    -webkit-animation: loader1 0.8s linear infinite alternate;
    animation: loader1 0.8s linear infinite alternate;
  }
  .loader1 span:nth-child(1) {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
    background: rgba(41, 103, 230, 0.6);
  }
  .loader1 span:nth-child(2) {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
    background: rgba(41, 103, 230, 0.8);
  }
  .loader1 span:nth-child(3) {
    -webkit-animation-delay: -0.26666s;
    animation-delay: -0.26666s;
    background: #2967e6;
  }
  .loader1 span:nth-child(4) {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
    background: rgba(41, 103, 230, 0.8);
  }
  .loader1 span:nth-child(5) {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
    background: rgba(41, 103, 230, 0.4);
  }
  
  @keyframes loader1 {
    from {
      -webkit-transform: scale(0, 0);
      transform: scale(0, 0);
    }
    to {
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1);
    }
  }
  
  @-webkit-keyframes loader1 {
    from {
      -webkit-transform: scale(0, 0);
    }
    to {
      -webkit-transform: scale(1, 1);
    }
  }
  .domain-search-cta-type {
    background-color: #f5f5f5 !important;
  }
  .domain-search-form input.form-control {
    border-color: #f5f5f5;
    text-indent: 10px;
  }
  .domain-search-form button.search-btn {
    color: #ffffff;
    padding-left: 30px;
    padding-right: 30px;
    border: 1px solid #1880ed;
    background: rgba(0, 115, 236, 0.9);
    -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.15);
    font-weight: 500;
    font-size: 14px;
    font-family: var(--font-family-secondary);
  }
  .domain-search-form button.search-btn:hover {
    color: #ffffff;
  }
  .btn-hover:hover:before,
  .btn-hover:hover:after {
    left: 0;
    right: auto;
    width: 100%;
  }
  .btn-hover:after,
  .btn-hover:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 0;
    z-index: -1;
    background: rgba(0, 115, 236, 0.9);
    -webkit-transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
  }
  .domain-search-list {
    position: relative;
    display: block;
  }
  .domain-search-list li:last-child:after {
    border: none;
  }
  .domain-search-list li:not(:last-child) {
    margin-right: 0 !important;
  }
  .domain-search-list li:last-child {
    border-right: none;
  }
  .domain-search-list li:first-child a {
    padding-left: 0;
  }
  .domain-search-list li a {
    display: inline-block;
    padding: 0 12px;
    text-align: center;
    font-weight: 600;
    color: inherit;
  }
  .domain-search-list li a img {
    max-width: 55px;
  }
  .domain-search-list li a span {
    font-size: 15px;
    line-height: 18px;
    margin-top: 8px;
    display: block;
    opacity: 0.7;
  }
  
  @media (min-width: 320px) and (max-width: 575px) {
    .domain-search-list li a {
      padding: 0;
    }
    .domain-search-list li:last-child {
      display: none;
    }
    .domain-search-list li:last-child:after {
      border: none;
    }
  }
  .custom-map-location {
    margin: 0;
    padding: 0;
    line-height: 0;
  }
  .custom-map-location li {
    list-style: none;
  }
  .network-map-wrap {
    position: relative;
    display: block;
    width: 100%;
  }
  .network-map-wrap ul li {
    position: absolute;
    z-index: 1;
    width: 10px;
    height: 10px;
    margin: -6px;
    background-color: #035ded;
    border-radius: 50%;
  }
  .network-map-wrap ul li span {
    display: block;
    width: 10px;
    height: 10px;
    -webkit-animation: ripple 1s linear infinite;
    animation: ripple 1s linear infinite;
    -webkit-transition: 0.5s linear;
    border-radius: 50%;
  }
  @-webkit-keyframes ripple {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 115, 236, 0.1),
        0 0 0 10px rgba(0, 115, 236, 0.1), 0 0 0 20px rgba(0, 115, 236, 0.1);
      box-shadow: 0 0 0 0 rgba(0, 115, 236, 0.1),
        0 0 0 10px rgba(0, 115, 236, 0.1), 0 0 0 20px rgba(0, 115, 236, 0.1);
    }
    100% {
      -webkit-box-shadow: 0 0 0 10px rgba(0, 115, 236, 0.1),
        0 0 0 20px rgba(0, 115, 236, 0.1), 0 0 0 30px rgba(0, 115, 236, 0);
      box-shadow: 0 0 0 10px rgba(0, 115, 236, 0.1),
        0 0 0 20px rgba(0, 115, 236, 0.1), 0 0 0 30px rgba(0, 115, 236, 0);
    }
  }
  
  @keyframes ripple {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(0, 115, 236, 0.1),
        0 0 0 10px rgba(0, 115, 236, 0.1), 0 0 0 20px rgba(0, 115, 236, 0.1);
      box-shadow: 0 0 0 0 rgba(0, 115, 236, 0.1),
        0 0 0 10px rgba(0, 115, 236, 0.1), 0 0 0 20px rgba(0, 115, 236, 0.1);
    }
    100% {
      -webkit-box-shadow: 0 0 0 10px rgba(0, 115, 236, 0.1),
        0 0 0 20px rgba(0, 115, 236, 0.1), 0 0 0 30px rgba(0, 115, 236, 0);
      box-shadow: 0 0 0 10px rgba(0, 115, 236, 0.1),
        0 0 0 20px rgba(0, 115, 236, 0.1), 0 0 0 30px rgba(0, 115, 236, 0);
    }
  }
  
  @-webkit-keyframes ripple-white {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
        0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
        0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1);
    }
    100% {
      -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1),
        0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1),
        0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0);
    }
  }
  
  @keyframes ripple-white {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
        0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1);
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.1),
        0 0 0 20px rgba(255, 255, 255, 0.1), 0 0 0 40px rgba(255, 255, 255, 0.1);
    }
    100% {
      -webkit-box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1),
        0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0);
      box-shadow: 0 0 0 20px rgba(255, 255, 255, 0.1),
        0 0 0 40px rgba(255, 255, 255, 0.1), 0 0 0 60px rgba(255, 255, 255, 0);
    }
  }
  .submit-request-form .form-group label {
    font-family: var(--font-family-primary);
    font-weight: 500;
    margin-bottom: 4px;
  }
  .input-file-wrap {
    position: relative;
    overflow: hidden;
    width: 100%;
  }
  .input-file-wrap input[type="file"] {
    position: absolute;
    left: 0;
    opacity: 0;
    top: 26px;
    cursor: pointer;
  }
  span.upfile-btn {
    position: relative;
    padding: 11px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border: 1px dashed #c3c3c3;
    text-align: center;
    width: 100%;
    display: block;
    background: #f5f5f5;
    cursor: pointer;
  }
  .offer-block {
    position: relative;
    display: block;
    overflow: hidden;
    width: 100%;
  }
  .offer-block:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    /* background: url("../img/offer-bg.png") no-repeat center center/cover; */
  }
  .vps-hosting-pricing-table {
    overflow: visible;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    border-collapse: unset;
    border-spacing: 0 10px;
  }
  .domain-search-result-table {
    border-collapse: collapse;
  }
  .vps-hosting-pricing-table > thead {
    background-color: transparent;
  }
  .vps-hosting-pricing-table.vps-hosting-pricing-table-two {
    border-collapse: collapse;
  }
  .vps-hosting-pricing-table > thead > tr > th {
    border: none;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    vertical-align: middle;
    font-family: var(--font-family-primary);
  }
  .vps-pricing-row {
    text-align: right;
    -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    border: solid 1px #ebebeb;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .vps-pricing-row > td:first-child {
    border-left: 2px solid transparent;
    border-radius: 4px 0 0 4px;
  }
  .vps-pricing-row > td:last-child {
    border-right: 2px solid transparent;
    border-radius: 0 4px 4px 0;
    text-align: left;
  }
  .vps-hosting-pricing-table > tbody > tr.vps-pricing-row:hover {
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
  }
  .vps-hosting-pricing-table-two > thead {
    background: #035ded;
    color: #ffffff;
  }
  .vps-hosting-pricing-table-two > thead > tr > th:last-child {
    border-right: 1px solid #035ded;
  }
  .vps-hosting-pricing-table-two .vps-pricing-row > td:first-child {
    border-left: initial;
  }
  .vps-hosting-pricing-table-two .vps-pricing-row > td:last-child {
    border-right: initial;
  }
  .vps-hosting-pricing-table-two > tbody > tr.vps-pricing-row:hover {
    background: #f5f5f5;
    border: none;
  }
  .vps-hosting-pricing-table > tbody > tr > td {
    padding: 20px 20px;
    border-right: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
    vertical-align: middle;
  }
  .vps-hosting-pricing-table.domain-search-result-table > tbody > tr > td {
    font-size: 14px;
  }
  .vps-pricing-row > td {
    border: none;
  }
  .vps-hosting-pricing-table .rate {
    font-size: 20px;
    font-weight: 700;
    color: #035ded;
    font-family: var(--font-family-primary);
  }
  .vps-hosting-pricing-table .rate span {
    font-size: 12px;
    font-weight: 600;
    margin-left: 5px;
    opacity: 0.8;
    color: #757575;
  }
  .vps-hosting-pricing-table .score-bar-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .vps-hosting-pricing-table .geekbench-score {
    font-weight: 400;
    margin-left: 15px;
  }
  .vps-hosting-pricing-table .score-bar-wrap .score-bar-item {
    min-width: 40px;
    height: 10px;
    font-size: 10px;
    border-radius: 10px;
    background-color: #ebebeb;
    position: relative;
  }
  .vps-hosting-pricing-table .pricing-onsale,
  .vps-hosting-pricing-table .cpu-core {
    display: block;
    font-size: 14px;
    color: #757575;
    margin-top: 4px;
    font-weight: 400;
  }
  .vps-hosting-pricing-table > tbody > tr > td:first-child::before {
    content: attr(data-value);
    display: none;
    width: initial;
    height: initial;
    background-color: transparent;
    position: relative;
    left: initial;
    top: initial;
    opacity: 1;
  }
  .vps-pricing-row > td:before {
    content: attr(data-value);
    display: none;
  }
  table > tbody > tr > td:first-child:before {
    content: "";
    display: block;
    width: 3px;
    height: 100%;
    background-color: #035ded;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
  
  @media (max-width: 991px) {
    .vps-hosting-pricing-table > thead {
      display: none;
    }
    .vps-pricing-row {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      border-radius: 4px;
      width: 45%;
      float: left;
      margin: 0 15px 28px 15px;
    }
    .vps-hosting-pricing-table > tbody > tr {
      padding: 15px;
    }
    .vps-hosting-pricing-table > tbody > tr > td {
      padding: 10px 15px;
      font-size: 14px;
      font-weight: normal;
    }
    .vps-pricing-row:hover.border-accent-primary > td:first-child {
      margin-bottom: 0;
      border-radius: 0;
      border-left: none;
    }
    .vps-pricing-row > td:first-child {
      border-left: none;
      border-radius: 0;
    }
    .vps-hosting-pricing-table > tbody > tr > td:first-child::before {
      display: block;
      width: 45%;
    }
    .vps-pricing-row > td:before {
      display: block;
      width: 45%;
    }
    .vps-hosting-pricing-table > tbody > tr > td {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .vps-pricing-row > td:last-child::before {
      display: none;
    }
    .vps-pricing-row > td:last-child {
      text-align: center !important;
      margin: auto;
    }
  }
  
  @media (max-width: 768px) {
    .vps-pricing-row {
      width: 100%;
      float: none;
      margin-right: auto;
      margin-bottom: 15px;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199px) {
    .vps-pricing-row td a.primary-solid-btn {
      padding: 8px 12px !important;
    }
  }
  .title-alert span.red {
    color: red;
  }
  .title-alert span.success {
    color: green;
  }
  .domain-filter-title,
  .domain-filter-list li label {
    cursor: pointer;
  }
  .domain-filter-list li label {
    display: inline-block;
    width: 100%;
  }
  .domain-filter-list li label .form-check-input {
    margin-top: 0.53rem;
  }
  
  @media (max-width: 1199.98px) {
    .domain-filter-list {
      display: none;
    }
    .domain-filter-title h5 span {
      display: block;
    }
  }
  
  @media (min-width: 992px) {
    .domain-filter-list {
      display: block !important;
    }
    .domain-filter-title h5 span {
      display: none !important;
    }
  }
  .vps-hosting-pricing-table.alert-table > tbody > tr > td {
    font-size: 18px;
  }
  .alert-table tbody tr {
    border-color: #031974;
    border-radius: 4px;
  }
  .alert-table .vps-pricing-row > td:first-child {
    border-left: 0;
  }
  .alert-table .vps-pricing-row > td:last-child {
    border-right: 0;
  }
  
  @media (max-width: 991px) {
    .vps-hosting-pricing-table.alert-table > tbody > tr > td {
      display: block;
    }
    .alert-table .vps-pricing-row {
      width: 100%;
      margin: 0;
    }
    .alert-table .vps-pricing-row > td:last-child {
      margin-left: 0;
    }
  }
  .action-wrap p {
    font-size: 14px;
  }
  .action-wrap a {
    font-size: 15px;
    font-weight: 400;
    font-family: var(--font-family-primary);
  }
  .action-wrap a span {
    margin-right: 5px;
  }
  .services-status-list li {
    border: 1px solid #ebebeb;
    -webkit-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    border-bottom: 2px solid transparent;
  }
  .services-status-list li:last-child {
    border-bottom: 1px solid #ebebeb;
  }
  .services-status-list li:hover {
    -webkit-box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1),
      inset 4px 0 0 0 rgba(100, 25, 255, 0.004);
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1),
      inset 4px 0 0 0 rgba(100, 25, 255, 0.004);
    border-bottom: 2px solid #035ded;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .status-location-heading {
    width: 70%;
    text-align: right;
  }
  .status-location-heading span {
    vertical-align: middle;
  }
  .status-mode {
    width: 30%;
    text-align: left;
  }
  .status-mode strong {
    font-size: 14px;
  }
  .timeline-item:not(:last-child) {
    margin-bottom: 2rem;
  }
  .timeline-item {
    position: relative;
    padding-left: 0.9375rem;
    padding-right: 2rem;
  }
  .timeline-item::before {
    position: absolute;
    top: 3.5rem;
    bottom: 0;
    right: 0;
    height: auto;
    border-left: 2px solid #ebebeb;
    content: "";
  }
  .timeline-heading::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid #ebebeb;
    content: "";
  }
  .timeline-icon {
    position: absolute;
    right: -0.875rem;
  }
  .timeline-icon.btn-sm {
    font-size: 18px;
    width: 2.4rem;
    height: 2.4rem;
    background: #ebebeb;
  }
  .timeline-heading {
    position: relative;
    display: block;
    font-size: 1.15rem;
    font-weight: 600;
    padding-bottom: 0.75rem;
    margin-bottom: 1rem;
    font-family: var(--font-family-secondary);
  }
  .card-bottom-line {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
    -webkit-transition: background 0.3s ease-in-out,
      -webkit-box-shadow 0.3s ease-in-out;
    transition: background 0.3s ease-in-out, -webkit-box-shadow 0.3s ease-in-out;
    transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    transition: background 0.3s ease-in-out, box-shadow 0.3s ease-in-out,
      -webkit-box-shadow 0.3s ease-in-out;
  }
  .card-bottom-line:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: #035ded;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
  }
  .partner-logo img {
    max-width: 125px;
  }
  .card-bottom-line:hover:after {
    width: 100%;
  }
  a.view-details-link {
    font-size: 14px;
    font-weight: 600;
  }
  a.view-details-link span {
    padding-left: 2px;
    font-size: 11px;
    vertical-align: middle;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
  }
  a.view-details-link:hover span {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
    margin-right: 6px;
  }
  .ratting-list ul {
    line-height: 0;
    margin: 0;
    padding: 0;
  }
  .ratting-list li:not(:last-child) {
    margin-right: -1px !important;
  }
  .ratting-list li {
    line-height: 0;
  }
  .ratting-list li span {
    font-size: 12px;
    padding-right: 2px;
    color: #fbae00;
    line-height: 16px;
  }
  .ratting-author h6 {
    font-weight: 500;
    font-size: 16px;
    display: inline-block;
  }
  .ratting-author small {
    text-align: right;
    float: right;
  }
  .error-content .notfound-404 {
    position: relative;
    height: 280px;
    z-index: 2;
    margin-bottom: 30px;
  }
  .error-content .notfound-404 h1 {
    font-size: 230px;
    font-weight: 700;
    margin: 0;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-size: cover;
    background-position: center;
  }
  
  @media (min-width: 320px) and (max-width: 767px) {
    .error-content .notfound-404 {
      height: auto;
      display: block;
      position: relative;
    }
    .error-content .notfound-404 h1 {
      font-size: 80px;
      position: relative;
    }
  }
  .page-header-section {
    position: relative;
    display: block;
    width: 100%;
    overflow: hidden;
  }
  .page-header-breadcrumb .custom-breadcrumb ol li a,
  .page-header-breadcrumb .custom-breadcrumb ol li.breadcrumb-item.active {
    color: inherit;
    opacity: 0.7;
  }
  .custom-breadcrumb ol li.list-inline-item:not(:last-child) {
    margin-left: 0;
  }
  .custom-breadcrumb ol {
    margin: 0;
    padding: 0;
    line-height: 0;
  }
  .custom-breadcrumb ol li a,
  .custom-breadcrumb ol li.breadcrumb-item.active {
    opacity: 0.7;
    font-size: 13px;
    font-family: var(--font-family-primary);
    font-weight: 600;
  }
  .custom-breadcrumb ol li a:hover,
  .page-header-breadcrumb .custom-breadcrumb ol li a:hover {
    opacity: 1;
  }
  .custom-breadcrumb ol li.breadcrumb-item {
    padding-left: 0;
  }
  .custom-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    font-family: "Font Awesome 6 Pro";
    content: "\f104";
    font-size: 11px;
    font-weight: 600;
    color: inherit;
    float: none;
  }
  .sidebar-content-wrap {
    border: 1px solid #ebebeb;
  }
  .sidebar-list li {
    border-bottom: 1px dashed #ebebeb;
  }
  .sidebar-list li a {
    display: block;
    font-size: 14px;
    line-height: 40px;
    font-weight: 600;
    color: #757575;
    font-family: var(--font-family-secondary);
  }
  .sidebar-list li a:hover {
    color: #035ded;
  }
  .btn.border-radius {
    border-radius: 4px;
  }
  .login-signup-form .form-group {
    position: relative;
  }
  .login-signup-form .form-group label {
    font-family: var(--font-family-primary);
    font-weight: 500;
    font-size: 88%;
  }
  .login-signup-form .form-group .input-group.input-group-merge > .form-control {
    text-indent: 35px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .login-signup-form
    .form-group
    .input-group.input-group-merge
    > .form-control:focus {
    z-index: inherit;
  }
  .input-icon {
    height: calc(2.75rem + 2px);
    position: absolute;
    z-index: 2;
    display: block;
    width: 40px;
    top: 0;
    bottom: 0;
    left: 0;
  }
  .input-icon span {
    color: #b1b1b1;
    padding: 16px 15px;
    position: absolute;
    font-size: 16px;
    height: calc(2.75rem + 2px);
    border-radius: 4px;
  }
  .textarea-icon span {
    height: calc(7.8rem + 2px);
  }
  .full-screen {
    min-height: 100vh;
    position: relative;
    width: 100%;
    z-index: 1;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .login-signup-content {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .bg-cover {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
  }
  .ml-n3,
  .mx-n3 {
    margin-left: -0.98rem !important;
  }
  .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.5;
    color: #95aac9;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #d2ddec;
    border-radius: 0.375rem;
  }
  .login-signup-card {
    position: relative;
    display: block;
  }
  .custom-control-label {
    line-height: 1.5;
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    font-size: 14px;
  }
  .login-signup-card small,
  .login-signup-card a.small {
    font-size: 14px;
    font-weight: 600;
  }
  .single-blog-card {
    margin-bottom: 25px;
  }
  .single-blog-card span.category {
    z-index: 2;
    top: 10px;
    left: 10px;
  }
  .single-blog-card .card-body {
    padding: 1.75rem;
  }
  .meta-list {
    opacity: 0.8;
    font-size: 95%;
  }
  .meta-list li.list-inline-item:not(:last-child) {
    margin-left: 0.7rem;
  }
  .single-blog-card a {
    font-family: var(--font-family-primary);
  }
  .single-blog-card .card-body h3 a {
    font-weight: 600;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }
  .single-blog-card a,
  .single-blog-card a:hover {
    text-decoration: none;
    color: inherit;
  }
  a.detail-link {
    display: inline-block;
    text-decoration: none;
    color: #495057;
    font-weight: 600;
    font-size: 13px;
    font-family: var(--font-family-primary);
  }
  a.detail-link span {
    font-size: 12px;
    vertical-align: middle;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    color: #035ded;
  }
  a.detail-link:hover,
  .single-blog-card .card-body h3 a:hover {
    color: #035ded;
  }
  a.detail-link:hover span {
    -webkit-transform: translateX(-3px);
    transform: translateX(-3px);
    margin-right: 8px;
  }
  .row-post-thumbnail .post {
    border: 0;
    padding-bottom: 25px;
    margin-bottom: 25px;
  }
  .post {
    border-bottom: 1px solid #f0f0f0;
    padding: 0 0 35px;
    margin: 0 0 45px;
  }
  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }
  .post-preview {
    padding-bottom: 5px;
    border-radius: 0.1875rem;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .post-preview img {
    border-radius: 0.1875rem;
    width: 100%;
    display: block;
  }
  .post-header {
    margin: 15px 0 20px;
  }
  .post-header .post-title {
    font-size: 1.5625rem;
    margin-bottom: 0;
    color: #333333;
  }
  .post-header .post-title a {
    color: #5d5d5d;
  }
  .post-header .post-title a:hover {
    color: #035ded;
  }
  .post-meta {
    list-style: none;
    font-size: 0.8125rem;
    padding: 0;
    margin: 0;
  }
  .post-meta a,
  .post-meta li {
    color: #495057;
    font-weight: 600;
  }
  .post-meta a:hover {
    color: #035ded;
    opacity: 1;
  }
  .post-meta > li {
    position: relative;
    display: inline-block;
    padding: 0 0 0 14px;
    margin: 0 0 0 14px;
  }
  .post-meta > li:not(:last-child):after {
    position: absolute;
    content: "/";
    left: -5px;
    top: 0;
  }
  .post-content p {
    margin-bottom: 5px;
  }
  .post-more a span {
    vertical-align: middle;
  }
  .post-footer {
    margin-top: 15px;
  }
  .post-content blockquote {
    border-left: 2px solid #eaeaea;
    padding: 10px 0 10px 40px;
    margin: 40px 0;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .post-content blockquote:hover {
    border-color: #035ded;
  }
  .blockquote {
    font-weight: 500;
    color: #222;
  }
  .post-content ol {
    list-style-type: decimal;
  }
  .post-content ul,
  .post-content ol {
    margin: 15px 0 15px 40px;
  }
  .comment,
  .comment-shop {
    border-bottom: 1px solid #f0f0f0;
    padding: 30px 0 0;
  }
  .comment-author,
  .comment-author-shop {
    margin: 5px 0 0;
    height: 64px;
    width: 64px;
    float: right;
  }
  .comment-body,
  .comment-body-shop {
    position: relative;
    padding: 30px 0 0;
    margin: 0 85px 0 0;
  }
  .comment-meta,
  .comment-meta-shop {
    margin: 0 0 15px;
  }
  .comment-meta-date {
    font-size: 13px;
  }
  .children > .comment,
  .children > .comment-shop {
    border: 0;
    border-top: 1px solid #f0f0f0;
    margin-left: 85px;
  }
  .comment-meta-author a,
  .comment-meta-author-shop a {
    font-weight: 500;
    color: #222;
  }
  .comment-meta-date a,
  .comment-meta-date-shop a {
    color: #788487;
    opacity: 0.6;
  }
  .comment-meta-date a:hover,
  .comment-meta-date a:focus,
  .comment-meta-date-shop a:hover,
  .comment-meta-date-shop a:focus {
    color: #035ded;
    opacity: 1;
  }
  .comment-reply,
  .comment-reply-shop {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 13px;
  }
  .comment-reply > a,
  .comment-reply-shop > a {
    background: #f4f4f4;
    border-radius: 0.1875rem;
    padding: 5px 15px;
    display: block;
    color: #222;
  }
  .comment-reply > a:hover,
  .comment-reply > a:focus,
  .comment-reply-shop > a:hover,
  .comment-reply-shop > a:focus {
    background: #035ded;
    color: #fff;
  }
  .comment-list + .comment-respond,
  .comment-list-shop + .comment-respond {
    margin-top: 30px;
  }
  .custom-pagination-nav ul li {
    text-align: center;
    margin-right: 10px;
  }
  .custom-pagination-nav ul li:focus,
  .custom-pagination-nav ul li a:focus {
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .custom-pagination-nav ul li a.page-link {
    width: 35px;
    height: 35px;
    line-height: 33px;
    border-radius: 100%;
    padding: inherit;
    font-family: var(--font-family-primary);
    font-weight: 600;
  }
  .custom-pagination-nav ul li.page-item:first-child .page-link,
  .custom-pagination-nav ul li.page-item:last-child .page-link {
    border: none;
    background: transparent;
  }
  .widget-search {
    padding: 0;
    border: 0;
  }
  .widget {
    margin-bottom: 30px;
  }
  .widget-search form {
    position: relative;
  }
  .widget-search .search-button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 50%;
    height: 100%;
    right: 0.875rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
    color: #ddd;
  }
  .widget-search .search-button span {
    font-size: 20px;
    line-height: 32px;
  }
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    -webkit-appearance: button;
  }
  .widget .widget-title {
    margin-bottom: 24px;
  }
  .widget .widget-title > h6 {
    text-transform: uppercase;
    font-family: var(--font-family-primary);
    font-weight: 600;
    font-size: 12px;
    margin: 0;
    color: #788487;
  }
  .widget ul {
    margin-bottom: 0;
  }
  .widget-categories ul li:first-child,
  .widget-recent-entries ul li:first-child,
  .widget-recent-entries-custom ul li:first-child {
    padding-top: 0;
  }
  .widget-categories ul li,
  .widget-recent-entries ul li,
  .widget-recent-entries-custom ul li {
    border-bottom: 1px solid #f0f0f0;
    padding: 10px 0;
    margin: 0;
  }
  .widget a {
    color: #788487;
    display: block;
    font-size: 14px;
  }
  .widget a:hover {
    color: #035ded;
  }
  .widget-recent-entries-custom .wi {
    float: left;
    width: 80px;
  }
  .widget-recent-entries-custom .wb {
    padding-left: 16px;
    width: 100%;
  }
  .widget-recent-entries-custom .wi,
  .widget-recent-entries-custom .wb {
    vertical-align: middle;
    display: table-cell;
  }
  .post-date {
    font-size: 13px;
    color: #a3a6a8;
    display: block;
    margin: 0;
  }
  .tag-cloud > a,
  .post-tags > a {
    background: #f4f4f4;
    border-radius: 0.1875rem;
    position: relative;
    display: inline-block;
    padding: 6px 15px;
    margin: 5px 8px 5px 0;
    text-transform: uppercase;
    font-size: 11px !important;
    color: #757575;
    font-weight: 500;
    font-family: var(--font-family-primary);
  }
  .tag-cloud > a:hover,
  .post-tags > a:hover {
    background: #035ded;
    color: #fff;
    opacity: 1;
  }
  
  @media (min-width: 320px) and (max-width: 767px) {
    .sidebar-right {
      padding-left: 0 !important;
    }
  }
  .single-promo-card {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .card.single-promo-card {
    border: 1px solid #ebebeb;
    border-radius: 1rem;
    z-index: 1;
  }
  .single-promo-card .card-body {
    padding: 1.5rem;
  }
  .link-color {
    color: #707070;
  }
  .google-map {
    height: 100%;
    margin-bottom: -8px;
  }
  .google-map iframe {
    width: 100%;
    border: 0;
    -webkit-filter: grayscale(99%);
    -webkit-backface-visibility: hidden;
  }
  .scroll-top.open {
    bottom: 15%;
  }
  button.scroll-top:focus {
    outline: none;
  }
  .scroll-top {
    width: 40px;
    height: 40px;
    line-height: 40px;
    position: fixed;
    bottom: 105%;
    right: 30px;
    font-size: 16px;
    border-radius: 4px;
    z-index: 999;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    background: #035ded;
    -webkit-transition: 1s ease;
    transition: 1s ease;
    border: none;
  }
  .scroll-top span {
    color: #fff;
  }
  .scroll-top:after {
    position: absolute;
    z-index: -1;
    content: "";
    top: 100%;
    left: 5%;
    height: 10px;
    width: 90%;
    opacity: 1;
    background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0.25) 0%,
      rgba(0, 0, 0, 0) 80%
    );
  }
  .footer-top {
    padding: 65px 0;
  }
  .security-icon-list li {
    margin: 0 15px 12px 0;
  }
  
  @media (min-width: 320px) and (max-width: 567px) {
    .footer-top {
      padding: 50px 0;
    }
  }
  .footer-nav-wrap h4 {
    font-size: 15px;
    margin-bottom: 1.4rem;
    font-weight: 600;
  }
  .footer-nav-wrap ul li a {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 0;
    color: inherit;
    opacity: 0.8;
  }
  .footer-nav-wrap ul li a:hover,
  .copyright-text a:hover,
  .social-list li a:hover {
    opacity: 1;
    text-decoration: none;
  }
  .payment-method ul {
    padding-top: 6px;
  }
  .payment-method ul li:last-child img {
    margin-right: 0;
  }
  .copyright-text {
    font-size: 14px;
    font-weight: 500;
    font-family: var(--font-family-primary);
  }
  .social-list li a {
    padding: 6px 10px;
  }
  .footer-nav-list li a span {
    font-size: 12px;
    vertical-align: middle;
  }
  .bottom-sticky-footer {
    position: absolute;
    bottom: 1px;
    width: 100%;
    height: 55px;
  }
  .social-nav ul li a {
    color: #ffffff;
    opacity: 0.8;
    -webkit-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }
  .tooltip-hover {
    position: relative;
  }
  .tooltip-hover a {
    display: block;
  }
  .tooltip-item {
    position: absolute;
    background-color: white;
    pointer-events: none;
    color: #333333;
    font-size: 13px;
    bottom: 90%;
    left: 50%;
    opacity: 0;
    white-space: nowrap;
    visibility: hidden;
    z-index: 999;
    -webkit-box-shadow: rgba(0, 0, 0, 0.2) 0 4px 15px;
    box-shadow: rgba(0, 0, 0, 0.2) 0 4px 15px;
    -webkit-transform: translate3d(-50%, 0px, 0px);
    transform: translate3d(-50%, 0px, 0px);
    padding: 0.4rem 0.8rem;
    border-radius: 3px;
    -webkit-transition: all 0.35s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    transition: all 0.35s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
  }
  .tooltip-item:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0px;
    bottom: -6px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    border-top: 6px solid white;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
  }
  .tooltip-hover:hover .tooltip-item {
    opacity: 1;
    visibility: visible;
    bottom: calc(100% + 5px);
  }
  .gallery-img {
    margin: 15px 0;
  }
  .gallery-img a {
    display: block;
  }
  .gallery-img img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    display: block;
  }
  .gallery-img:hover img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
  .gallery-img-slider .gallery-img img {
    -webkit-filter: grayscale(0);
    filter: grayscale(0);
  }
  .hs-menu-initialized.hs-menu-horizontal .hs-mega-menu.hs-position-right {
    right: auto;
    left: 0;
  }
  .main-navbar-nav {
    margin-left: 0 !important;
  }
  .navbar-brand {
    margin-right: 0;
  }
  
  @media (min-width: 768px) {
    .navbar-expand-md .main-navbar-nav .header-nav-last-item {
      padding-left: 0;
      padding-right: 1rem;
    }
    .navbar-expand-md .main-navbar-nav .sub-menu-nav-group .sub-menu-nav-link {
      padding-right: 0;
    }
  }
  .hs-sub-menu .hs-sub-menu,
  .hs-mega-menu .hs-sub-menu,
  .hs-sub-menu .hs-mega-menu,
  .hs-mega-menu .hs-mega-menu {
    right: 100%;
  }
  .features-box-content {
    padding-left: 0;
    padding-right: 20px;
  }
  /* .action-btns a.btn:first-of-type {
    margin: 0 !important;
  } */
  .menu-titile-icon {
    margin-right: 0;
    margin-left: 1rem;
  }
  .main-link-toggle::after {
    margin-right: 0.4rem;
    margin-left: 0;
  }
  .ratting-author small {
    float: left;
  }
  .list-inline-item:not(:first-child) {
    margin-right: 0;
  }
  .scroll-top {
    left: 30px !important;
    right: auto;
  }
  .page-header-section:after {
    left: 0;
    right: auto;
  }
  .sub-link-toggle::after {
    content: "\e64a";
    margin-right: auto;
    margin-left: 0.04rem;
  }
  .tech-feature-list li span {
    float: right;
    line-height: inherit;
    vertical-align: middle;
  }
  .tech-feature-list li span {
    content: "\e662" !important;
  }
  .accordion-faq .card-header:after {
    float: left;
  }
  .owl-carousel {
    direction: initial !important;
  }
  .feature-tab li {
    border-left: 0 !important;
  }
  .feature-tab li:first-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .feature-new-tab li:last-child {
    border-left: 1px solid #0073ec !important;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .right-arrow {
    margin-right: 25px;
  }
  .left-arrow {
    margin-left: 25px;
  }
  .domain-search-form {
    margin-left: auto;
    direction: rtl;
  }
  .custom-dot.dot-right-center.owl-theme .owl-dots {
    left: 10%;
  }
  .item .hero-equal-height .row {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
  }
  .hero-content-slider .item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
  }
  .widget-search .search-button {
    left: 0.875rem;
    right: auto;
  }
  .post-content blockquote {
    border-left: none;
    border-right: 2px solid #eaeaea;
    padding: 10px 40px 10px 10px;
  }
  .comment-reply,
  .comment-reply-shop {
    left: 0;
    right: auto;
  }
  .input-icon {
    left: auto;
    right: 0;
  }
  .custom-overlay:before {
    position: absolute;
    content: "";
    background: rgba(113, 0, 184, 0.35);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .offer-tag-wrap img {
    position: relative;
    display: block;
    width: 100%;
  }
  .offer-tag {
    padding: 15px;
    position: absolute;
    left: 0;
    right: 0;
    top: 25%;
    color: #ffffff;
  }
  .offer-price-tag {
    font-size: 100px;
    line-height: 115px;
    font-weight: 700;
    font-family: var(--font-family-primary);
  }
  .offer-price-tag small,
  .offer-price-tag span {
    font-size: 25px;
    line-height: initial;
  }
  .offer-price-tag span {
    position: relative;
    top: -40px;
  }
  .offer-price-tag p {
    line-height: initial;
  }
  .ribbon-2 {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: #ff5919;
    border-radius: 30px;
  }
  .ribbon-2 span {
    font-size: 20px;
    font-family: var(--font-family-primary);
    font-weight: 500;
    padding: 0 15px 0 25px;
  }
  .single-offer-box {
    background: #161e76;
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 10px 0;
  }
  .content {
    margin: 12px;
    border: 1px solid #103f6e;
    border-radius: 10px;
    width: 100%;
  }
  .discount-price {
    font-size: 30px;
    line-height: 55px;
    font-weight: 600;
    font-family: var(--font-family-primary);
  }
  .discount-price small {
    font-size: 18px;
  }
  .pass {
    padding: 10px 15px;
    border-radius: 10px 10px 0 0;
    background: #ffffff;
  }
  .pass h6 {
    font-size: 14px;
    font-weight: 600;
    font-family: var(--font-family-primary);
  }
  .pass::before {
    content: "";
    position: absolute;
    bottom: -8px;
    left: calc(50% - 20px);
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 10px solid #ffffff;
  }
  .offer-price-content .btn {
    margin-top: 10px;
    padding: 8px 25px;
  }
  @media (min-width: 320px) and (max-width: 575px) {
    .action-btns .btn {
      margin: 6px 0;
    }
  }
  .countdown-list li {
    margin: 6px 0;
  }
  .countdown-list li h6 {
    width: 62px;
    height: 62px;
    line-height: 62px;
    font-size: 25px;
    font-family: "Open Sans", sans-serif;
    color: #707070;
  }
  .countdown-list span {
    font-size: 14px;
  }
  .radio-box-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-transition: all 0.25s linear;
    transition: all 0.25s linear;
  }
  .radio-box-wrap .single-radio-box {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-transition: all 0.25s linear;
    transition: all 0.25s linear;
    display: block;
  }
  .single-radio-box {
    display: block;
    position: relative;
    border: 1px solid #ebebeb;
    margin: 5px 12px;
    border-radius: 4px;
    width: 100%;
  }
  .single-radio-box input[type="radio"] {
    position: absolute;
    visibility: hidden;
  }
  .single-radio-box label {
    font-family: var(--font-family-primary);
    display: block;
    position: relative;
    padding: 10px;
    z-index: 9;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
    margin-bottom: 0;
  }
  .single-radio-box label .custom-check {
    display: block;
    position: absolute;
    border: 2px solid transparent;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    left: 50%;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    top: -30%;
    z-index: 5;
    transition: border 0.25s linear;
    -webkit-transition: border 0.25s linear;
  }
  .single-radio-box label .custom-check:before {
    display: block;
    position: absolute;
    border-radius: 100%;
    font-size: 14px;
    height: 11px;
    width: 11px;
    left: auto;
    right: -1px;
    -webkit-transform: translate(-50%);
    transform: translate(-50%);
    top: 0;
    bottom: 60%;
    margin: auto;
    transition: background 0.25s linear;
    -webkit-transition: background 0.25s linear;
  }
  .single-radio-box input[type="radio"]:checked ~ label .custom-check {
    border: 2px solid #035ded;
    background: #fff;
  }
  .single-radio-box input[type="radio"]:checked ~ label .custom-check::before {
    font-family: "Font Awesome 6 Pro";
    font-weight: 900;
    content: "\f00c";
    color: #035ded;
  }
  .single-radio-box input[type="radio"]:checked ~ label {
    color: #fff;
    background: #035ded;
    border-radius: 4px;
  }
  .yearly-price,
  .biannual-price,
  .triennial-price {
    display: none;
  }
  